import { useEffect, useState } from 'react'
import AppService from 'services/apps'
import { App } from 'types'

export const useApps = (): [ data: App[], error: Error | object, isLoading: boolean ] => {
  const [data, setData] = useState<App[]>([])
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    AppService.getAll<App[]>()
      .then((data) => {
        setData(data)
      })
      .catch((error: Error) => {
        setError(error)
        console.error('Error getting Apps info: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return [data, error, isLoading]
}
