import CustomSelect, { CustomSelectProps } from 'components/Form/CustomSelect'
import { FieldHookConfig, useField } from 'formik'
import validateInput from 'validations/input'

const FormikSelect = ({ className = '', ...props }: CustomSelectProps & FieldHookConfig<string>): JSX.Element => {
  const [field, meta] = useField(props)

  return (
    <>
      <CustomSelect
        className={`${className} ${validateInput(meta.touched, meta.error)}}`}
        {...field} {...props}
      />
      {meta.error && (<div className='invalid-feedback'>{meta.error}</div>)}
    </>
  )
}

export default FormikSelect
