import { ApplicationVersion } from 'types'

const mockedApplicationVersions: ApplicationVersion[] = [
  {
    id: '1.4.5',
    applicationId: 'com.enthought.foo',
    title: 'Title',
    description: 'Description',
    icon: {},
    profiles: [
      {
        name: 'Small',
        cpu: 3,
        gpu: 1,
        memory: 2,
        shutdown: '',
        url: '#'
      },
      {
        name: 'Medium',
        cpu: 3,
        gpu: 1,
        memory: 1,
        shutdown: '',
        url: '#'
      }
    ]
  },
  {
    id: '5.4.5',
    applicationId: 'com.enthought.foo',
    title: 'Title',
    description: 'Description',
    icon: {},
    profiles: [
      {
        name: 'Small',
        cpu: 3,
        gpu: 1,
        memory: 2,
        shutdown: '',
        url: '#'
      },
      {
        name: 'Medium',
        cpu: 3,
        gpu: 1,
        memory: 1,
        shutdown: '',
        url: '#'
      }
    ]
  },
  {
    id: '1.2.5',
    applicationId: 'com.enthought.bar',
    title: 'Title',
    description: 'Description',
    icon: {},
    profiles: [
      {
        name: 'Small',
        cpu: 3,
        gpu: 1,
        memory: 2,
        shutdown: '',
        url: '#'
      },
      {
        name: 'Medium',
        cpu: 3,
        gpu: 1,
        memory: 1,
        shutdown: '',
        url: '#'
      }
    ]
  },
  {
    id: '3.4.5',
    applicationId: 'com.enthought.bar',
    title: 'Title',
    description: 'Description',
    icon: {},
    profiles: [
      {
        name: 'Small',
        cpu: 3,
        gpu: 1,
        memory: 2,
        shutdown: '',
        url: '#'
      },
      {
        name: 'Medium',
        cpu: 3,
        gpu: 1,
        memory: 1,
        shutdown: '',
        url: '#'
      }
    ]
  },
  {
    id: '2.4.5',
    applicationId: 'com.enthought.baz',
    title: 'Title',
    description: 'Description',
    icon: {},
    profiles: [
      {
        name: 'Small',
        cpu: 3,
        gpu: 1,
        memory: 2,
        shutdown: '',
        url: '#'
      },
      {
        name: 'Medium',
        cpu: 3,
        gpu: 1,
        memory: 1,
        shutdown: '',
        url: '#'
      }
    ]
  },
  {
    id: '4.4.5',
    applicationId: 'com.enthought.baz',
    title: 'Title',
    description: 'Description',
    icon: {},
    profiles: [
      {
        name: 'Small',
        cpu: 3,
        gpu: 1,
        memory: 2,
        shutdown: '',
        url: '#'
      },
      {
        name: 'Medium',
        cpu: 3,
        gpu: 1,
        memory: 1,
        shutdown: '',
        url: '#'
      }
    ]
  }
]

export default mockedApplicationVersions
