export interface CustomSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children?: React.ReactNode | React.ReactNode[]
  emptyDefault?: boolean
  label?: string
  options?: Array<string | number> | (() => [])
}

const CustomSelect = ({ label, className = '', emptyDefault = true, children, options, ...props }: CustomSelectProps): JSX.Element => {
  const id = props.id ?? props.name

  const renderOptions = (): React.ReactNode[] | null => {
    if (typeof options === 'function') {
      return options()
    }

    if (Array.isArray(options)) {
      return options.map((opt) => <option key={opt} value={opt}>{opt}</option>)
    }

    return null
  }

  return (
    <>
      {label && (<label htmlFor={id} className='form-label'>{label}</label>)}
      <select
        id={id}
        className={`form-select ${className}`}
        {...props}
      >
        {emptyDefault && <option value='' />}
        {children ?? renderOptions()}
      </select>
    </>
  )
}

export default CustomSelect
