import { Server } from 'types'

const mockedServers: Server[] = [
  {
    id: 'quay.io',
    applicationId: 'com.enthought.foo',
    username: 'someuser_1'
  },
  {
    id: 'quay2.io',
    applicationId: 'com.enthought.foo',
    username: 'someuser_2'
  },
  {
    id: 'quay3.io',
    applicationId: 'com.enthought.foo',
    username: 'someuser_3'
  },
  {
    id: 'quay.io',
    applicationId: 'com.enthought.bar',
    username: 'someuser_1'
  },
  {
    id: 'quay2.io',
    applicationId: 'com.enthought.bar',
    username: 'someuser_2'
  },
  {
    id: 'quay3.io',
    applicationId: 'com.enthought.bar',
    username: 'someuser_3'
  },
  {
    id: 'quay.io',
    applicationId: 'com.enthought.baz',
    username: 'someuser_1'
  },
  {
    id: 'quay2.io',
    applicationId: 'com.enthought.baz',
    username: 'someuser_2'
  },
  {
    id: 'quay3.io',
    applicationId: 'com.enthought.baz',
    username: 'someuser_3'
  }
]

export default mockedServers
