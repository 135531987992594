import { Icon } from 'types'

const RemoveIcon = ({ width = 14, height = 13, fill = '#FFFFFF', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 14 13' fill={fill} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1.93023 0.0200195L1.02023 0.930019L6.09023 6.00002L0.990234 11.11L1.89023 12.01L7.00023 6.91002L12.1002 12.01L13.0102 11.1L7.91023 6.00002L12.9802 0.930019L12.0702 0.0200195L7.00023 5.09002L1.93023 0.0200195Z' />
    </svg>
  )
}

export default RemoveIcon
