import { Icon } from 'types'

const CopyIcon = ({ width = 16, height = 16, fill = '#8A9099', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.70503 1.70503C1.8363 1.57375 2.01435 1.5 2.2 1.5H8.725C8.91065 1.5 9.0887 1.57375 9.21997 1.70503C9.35125 1.8363 9.425 2.01435 9.425 2.2V2.925C9.425 3.33921 9.76079 3.675 10.175 3.675C10.5892 3.675 10.925 3.33921 10.925 2.925V2.2C10.925 1.61652 10.6932 1.05694 10.2806 0.644365C9.86806 0.231785 9.30848 0 8.725 0H2.2C1.61652 0 1.05695 0.231785 0.644365 0.644365C0.231785 1.05695 0 1.61652 0 2.2V8.725C0 9.30848 0.231785 9.86806 0.644365 10.2806C1.05694 10.6932 1.61652 10.925 2.2 10.925H2.925C3.33921 10.925 3.675 10.5892 3.675 10.175C3.675 9.76079 3.33921 9.425 2.925 9.425H2.2C2.01435 9.425 1.8363 9.35125 1.70503 9.21997C1.57375 9.0887 1.5 8.91065 1.5 8.725V2.2C1.5 2.01435 1.57375 1.8363 1.70503 1.70503ZM6.57501 7.27501C6.57501 6.88841 6.88841 6.57501 7.27501 6.57501H13.8C14.1866 6.57501 14.5 6.88841 14.5 7.27501V13.8C14.5 14.1866 14.1866 14.5 13.8 14.5H7.27501C6.88841 14.5 6.57501 14.1866 6.57501 13.8V7.27501ZM7.27501 5.07501C6.05999 5.07501 5.07501 6.05999 5.07501 7.27501V13.8C5.07501 15.015 6.05999 16 7.27501 16H13.8C15.015 16 16 15.015 16 13.8V7.27501C16 6.05999 15.015 5.07501 13.8 5.07501H7.27501Z' fill={fill} />
    </svg>
  )
}

export default CopyIcon
