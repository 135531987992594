import { Link } from 'react-router-dom'
import { Breadcrumb } from 'types'

const BreadcrumbItem = ({ path, title, active = false }: Breadcrumb): JSX.Element => {
  if (active) {
    return (<li className='breadcrumb-item active' aria-current='page'>{title}</li>)
  }

  return (<li className='breadcrumb-item'><Link to={path}>{title}</Link></li>)
}

export default BreadcrumbItem
