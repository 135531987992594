import InternalSidebar from 'components/InternalSidebar'
import InternalSidebarItem from 'components/InternalSidebar/Item'
import internalSidebarItemtSyles from 'components/InternalSidebar/Item/styles.module.scss'
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import ApplicationContent from 'views/layouts/Application/Content'

import styles from './styles.module.scss'

const Settings: React.FC = () => {
  return (
    <ApplicationContent>

      {/* <!-- Internal Sidebar --> */}
      <InternalSidebar>
        <InternalSidebarItem>
          <NavLink className={({ isActive }: { isActive: boolean }) => isActive ? internalSidebarItemtSyles.active : ''} to='/settings/roles'>Roles</NavLink>
        </InternalSidebarItem>

        <InternalSidebarItem>
          <NavLink className={({ isActive }: { isActive: boolean }) => isActive ? internalSidebarItemtSyles.active : ''} to='/settings/applications'>Applications</NavLink>
        </InternalSidebarItem>

        <InternalSidebarItem>
          <NavLink className={({ isActive }: { isActive: boolean }) => isActive ? internalSidebarItemtSyles.active : ''} to='/settings/application-versions'>Application Versions</NavLink>
        </InternalSidebarItem>

        <InternalSidebarItem>
          <NavLink className={({ isActive }: { isActive: boolean }) => isActive ? internalSidebarItemtSyles.active : ''} to='/settings/server-information/com.enthought.baz'>Server Information</NavLink>
        </InternalSidebarItem>
      </InternalSidebar>

      {/* <!-- Internal Content --> */}
      <div className={styles.internalContent}>
        <div className='d-flex flex-column h-100'>
          <Outlet />
        </div>
      </div>
    </ApplicationContent>
  )
}

export default Settings
