import logo from 'assets/images/icons/enthought.svg'
import AppsIcon from 'components/DynamicIcons/Apps'
import SearchIcon from 'components/DynamicIcons/Search'

import SidebarItem from './Item'
import styles from './styles.module.scss'

const Sidebar: React.FC = () => {
  return (
    <aside className={styles.applicationSidebar}>
      <div className={styles.companyLogo}>
        <img src={logo} alt='Et' />
      </div>

      <SidebarItem path='/workbench' title='My Apps' icon={<AppsIcon />} active />
      <SidebarItem path='#' title='Search' icon={<SearchIcon />} />
    </aside>
  )
}

export default Sidebar
