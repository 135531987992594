import Alert from 'components/Alert'
import AppCard from 'components/AppCard'
import { useApps } from 'hooks/useApps'
import React from 'react'
import ApplicationContent from 'views/layouts/Application/Content'

const Workbench: React.FC = () => {
  const [apps, error, isLoading] = useApps()

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }

  if (typeof error === 'object' && Object.keys(error).length > 0) {
    return <Alert variant='danger'>Error loading Apps</Alert>
  }

  return (
    <ApplicationContent>
      <div className='container-apps bg-white flex-grow-1'>
        {apps.map((a) => <AppCard key={a.id} app={a} />)}
      </div>
    </ApplicationContent>
  )
}

export default Workbench
