import { Container } from 'types'
import styles from './styles.module.scss'

interface Props extends Container {
  active?: boolean
}

const InternalSidebarItem = ({ children, className = '', active = false, ...props }: Props): JSX.Element => {
  return (
    <li className={`${styles.applicationInternalSidebarItem} ${active ? styles.active : ''} ${className}`} {...props}>
      {children}
    </li>
  )
}

export default InternalSidebarItem
