import Button from 'components/Button'
import CopyIcon from 'components/DynamicIcons/Copy'
import EditIcon from 'components/DynamicIcons/Edit'
import SmallTrashIcon from 'components/DynamicIcons/SmallTrash'
import Modal from 'components/Modal'
import ServerForm from 'components/Server/Form'
import Table from 'components/Table'
import { useServers } from 'hooks/useServers'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Server } from 'types'

const initServer = (options?: Partial<Server>): Server => {
  const defaults: Server = {
    id: '',
    username: '',
    password: ''
  }

  return { ...defaults, ...options }
}

const ServerInformation: React.FC = () => {
  const { applicationId } = useParams()
  const [servers, setServers] = useServers(applicationId)
  const [selectedServer, setSelectedServer] = useState<Server>(initServer({ applicationId }))
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const handleCopy = useCallback((index: number) => {
    const currentServers = [...servers]
    const newServer = Object.assign({}, currentServers[index])
    newServer.id += `-${Math.ceil(Math.random() * 1000000)}`
    currentServers.push(newServer)
    setServers(currentServers)
  }, [servers])

  const handleDelete = useCallback((index: number) => {
    if (confirm('Are you sure?')) {
      const currentServers = [...servers]
      currentServers.splice(index, 1)
      setServers(currentServers)
    }
  }, [servers])

  const handleEdit = useCallback((index: number) => {
    const server = servers[index]
    setModalTitle(`Editing Server "${server.id}"`)
    setSelectedServer(initServer(server))
    setShowModal(true)
  }, [servers])

  const handleAdd = (): void => {
    setModalTitle('New Server')
    setSelectedServer(initServer())
    setShowModal(true)
  }

  const handleCancel = (): void => {
    setTimeout(() => setShowModal(false), 200)
  }

  const handleSave = (values: Server): void => {
    alert(JSON.stringify(values, null, 2))
  }

  return (
    <div className='px-1 mt-5 mx-4'>
      <div className='max-w-700'>
        <h5 className='fs-18'>Server Information</h5>

        <hr />
      </div>

      <Table striped hover borderless>
        <thead>
          <tr>
            <th scope='col'>Domain</th>
            <th scope='col'>Username</th>
            <th scope='col'>Password</th>
            <th scope='col' className='text-center'>Actions</th>
          </tr>
        </thead>
        <tbody className='border-top border-bottom border-light-medium'>
          {servers.map((s, i) => (
            <tr key={i}>
              <td>{s.id}</td>
              <td>{s.username}</td>
              <td>••••••••••</td>
              <td className='d-flex align-items-center justify-content-center'>
                <Button variant='link' className='px-1 py-0 mx-1' title='Edit' onClick={() => handleEdit(i)}>
                  <EditIcon fill='#8A9099' />
                </Button>
                <Button variant='link' className='px-1 py-0 mx-1' title='Copy' onClick={() => handleCopy(i)}>
                  <CopyIcon />
                </Button>
                <Button variant='link' className='px-1 py-0 mx-1' title='Delete' onClick={() => handleDelete(i)}>
                  <SmallTrashIcon />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className='d-flex justify-content-end'>
        <Button onClick={handleAdd}>+ Add Server</Button>
      </div>

      <Modal showModal={showModal} onClose={handleCancel} title={modalTitle}>
        <ServerForm initServerValues={selectedServer} onCancel={handleCancel} onSave={handleSave} />
      </Modal>
    </div>
  )
}

export default ServerInformation
