import Button from 'components/Button'
import CirclePlusIcon from 'components/DynamicIcons/CirclePlus'
import TrashIcon from 'components/DynamicIcons/Trash'
import FormikInput from 'components/Form/Formik/Input'
import { FieldArray } from 'formik'
import { ApplicationProfile } from 'types'

const initProfile = (options?: Partial<ApplicationProfile>): ApplicationProfile => {
  const defaults = {
    name: '',
    cpu: 1,
    gpu: 0,
    memory: 1,
    shutdown: '',
    url: ''
  }

  return { ...defaults, ...options }
}

interface Props {
  profiles?: ApplicationProfile[]
}

const ProfilesInput = ({ profiles = [] }: Props): JSX.Element => {
  return (
    <FieldArray
      name='profiles'
      render={arrayHelpers => (
        <>
          <div className='table-responsive'>
            <table className='table table-borderless table-narrow'>
              <thead>
                <tr>
                  <th style={{ minWidth: '135px' }} className='fw-medium'>Name</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>CPU</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>GPU</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>Memory (GB)</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>Shutdown</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {profiles.length > 0 && (
                  profiles.map((profile, i) => (
                    <tr key={i}>
                      <td>
                        <FormikInput
                          name={`profiles.${i}.name`}
                          className='text-start text-md-center'
                          type='text'
                        />
                      </td>
                      <td>
                        <FormikInput
                          name={`profiles.${i}.cpu`}
                          className='text-start text-md-center'
                          type='number'
                        />
                      </td>
                      <td>
                        <FormikInput
                          name={`profiles.${i}.gpu`}
                          className='text-start text-md-center'
                          type='number'
                        />
                      </td>
                      <td>
                        <FormikInput
                          name={`profiles.${i}.memory`}
                          className='text-start text-md-center'
                          type='number'
                        />
                      </td>
                      <td>
                        <FormikInput
                          name={`profiles.${i}.shutdown`}
                          className='text-start text-md-center'
                          type='text'
                        />
                      </td>
                      <td>
                        <Button variant='link' title='Delete' onClick={() => arrayHelpers.remove(i)}>
                          <TrashIcon />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <Button variant='link' onClick={() => arrayHelpers.push(initProfile())}>
            <CirclePlusIcon />
            <span>Add Profile</span>
          </Button>
        </>
      )}
    />
  )
}

export default ProfilesInput
