import Button from 'components/Button'
import FormikInput from 'components/Form/Formik/Input'
import { Form, Formik } from 'formik'
import { Form as FormType, Server } from 'types'
import domainValidation from 'validations/domain'
import * as Yup from 'yup'

const validationSchema = Yup.object({
  id: domainValidation()
    .required('Required'),
  username: Yup.string()
    .required('Required'),
  password: Yup.string()
    .required('Required')
})

interface Props extends FormType {
  initServerValues: Server
  onSave: (values: Server) => void
}

const ServerForm = ({ initServerValues, onCancel, onSave }: Props): JSX.Element => {
  return (
    <Formik
      enableReinitialize
      initialValues={initServerValues}
      validationSchema={validationSchema}
      onSubmit={onSave}
    >
      {() => (
        <Form className='needs-validation'>
          <div className='mb-3'>
            <FormikInput
              name='id'
              type='text'
              label='Domain'
            />
          </div>

          <div className='mb-3'>
            <FormikInput
              name='username'
              type='text'
              label='Username'
            />
          </div>

          <div className='mb-3'>
            <FormikInput
              name='password'
              type='password'
              label='Password'
            />
          </div>

          <hr className='my-4' />

          <div className='d-flex justify-content-end mb-2'>
            <Button variant='light' onClick={onCancel}>Cancel</Button>
            <Button type='submit' className='ms-0 ms-lg-3'>Save</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ServerForm
