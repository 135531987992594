import React, { useCallback, useState } from 'react'
import { ImageBase64 } from 'types'

export type ValidExtension = 'webp' | 'jpg' | 'jpeg' | 'png' | 'gif'

interface Props {
  accept?: string
  allowedExtensions?: ValidExtension[]
  name: string
  value?: ImageBase64
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const useImageToBase64 = ({ accept, allowedExtensions = ['png'], onChange, name, value = '' }: Props) => {
  const [image, setImage] = useState<ImageBase64>(value)
  const imagesAccepted = accept ?? allowedExtensions.reduce((a, v) => `${a}${a ? ', ' : ''}image/${v}`, '')

  const emitUpdate = useCallback((newImage: ImageBase64) => {
    if (typeof onChange === 'function') {
      onChange({ target: { name, value: newImage } } as unknown as React.ChangeEvent<HTMLInputElement>)
    }
  }, [onChange])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = event.target
    setImage(null)

    if ((inputFile.files != null) && inputFile.files[0]) {
      const reader = new window.FileReader()

      reader.onloadend = () => {
        setImage(reader.result)
        emitUpdate(reader.result)
      }

      reader.readAsDataURL(inputFile.files[0])
    }
  }, [emitUpdate, setImage])

  const handleRemove = useCallback(() => {
    setImage(null)
    emitUpdate('')
  }, [emitUpdate, setImage])

  return { image, handleChange, imagesAccepted, handleRemove }
}

export default useImageToBase64
