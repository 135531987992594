import { useRouteError } from 'react-router-dom'

interface CustomError extends Error {
  statusText?: string
}

const NotFound: React.FC = () => {
  const error: CustomError = useRouteError() as Error
  console.error(error)

  return (
    <div className='d-flex flex-column align-items-center justify-content-center min-h-100vh'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText ?? error.message}</i>
      </p>
    </div>
  )
}

export default NotFound
