import './assets/styles/styles.scss'
import { RouterProvider } from 'react-router-dom'
import router from './router'
import 'bootstrap'

const App: React.FC = () => {
  return (<RouterProvider router={router} />)
}

export default App
