import { useState } from 'react'
import MenuItem from '../MenuItem'
import NotificationsIcon from 'components/DynamicIcons/Notifications'
import styles from './styles.module.scss'

const NotificationsMenu: React.FC = () => {
  const [hasNotifications, setHasNotifications] = useState(true)

  return (
    <MenuItem className={hasNotifications ? styles.hasNotifications : ''}>
      <NotificationsIcon />
    </MenuItem>
  )
}

export default NotificationsMenu
