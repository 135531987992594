import { Container } from 'types'
import styles from './styles.module.scss'

const Content = ({ children, className = '' }: Container): JSX.Element => {
  return (
    <div className={`d-flex flex-grow-1 ${styles.applicationContent} ${className}`}>
      {children}
    </div>
  )
}

export default Content
