import mockedApps from 'mocks/apps'
import { App } from 'types'

class AppService {
  static getAll = async <T>(): Promise<T> => {
    return await new Promise((resolve, reject) => {
      resolve(mockedApps as T)
    })
  }

  static findById = async <T>(appId: App['id']): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const foundApp = mockedApps.find((a) => a.id === appId)
      resolve(foundApp as T)
    })
  }
}

export default AppService
