import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ApplicationService from 'services/applications'
import { ApplicationSettings } from 'types'

const initApplication = (options?: Partial<ApplicationSettings>): ApplicationSettings => {
  const defaults = {
    id: '',
    visibleVersions: [],
    autoAddNewVersions: false,
    limits: {
      instance: {
        cpu: 1,
        gpu: 0,
        memory: 1,
        shutdown: ''
      },
      global: {
        cpu: 1,
        gpu: 0,
        memory: 1,
        shutdown: ''
      }
    }
  }

  return { ...defaults, ...options }
}

export const useApplication = (applicationId?: ApplicationSettings['id']): { data: ApplicationSettings, setData: Dispatch<SetStateAction<ApplicationSettings>>, error: Error | object, isLoading: boolean } => {
  const [data, setData] = useState<ApplicationSettings>(initApplication())
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (applicationId) {
      setIsLoading(true)
      ApplicationService.findById<ApplicationSettings>(applicationId)
        .then((application) => {
          setData(application)
        })
        .catch((error: Error) => {
          setError(error)
          console.error(`Error getting application ${applicationId}'s info: `, error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [applicationId])

  return { data, setData, error, isLoading }
}
