import { Icon } from 'types'

const SearchIcon = ({ width = 29, height = 30, fill = '#0D384D' }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.44 0.919922C5.4375 0.919922 0.559998 5.79742 0.559998 11.7999C0.559998 17.8024 5.4375 22.6799 11.44 22.6799C13.815 22.6799 16.01 21.9149 17.8 20.6199L26.22 29.0199L28.02 27.2199L19.7 18.8799C21.335 16.9749 22.32 14.5024 22.32 11.7999C22.32 5.79742 17.4425 0.919922 11.44 0.919922ZM11.44 2.19992C16.75 2.19992 21.04 6.48992 21.04 11.7999C21.04 17.1099 16.75 21.3999 11.44 21.3999C6.13 21.3999 1.84 17.1099 1.84 11.7999C1.84 6.48992 6.13 2.19992 11.44 2.19992Z' fill={fill} />
    </svg>
  )
}

export default SearchIcon
