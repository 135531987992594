import React, { useContext } from 'react'
import { ListGroupItemProps } from 'types'

import { ItemContext } from '../../index'

type PropsAreEqual<P> = (
  prevProps: Readonly<P>,
  nextProps: Readonly<P>
) => boolean

const withListGroupItemStyle = <P extends ListGroupItemProps>(
  component: {
    (props: P): Exclude<React.ReactNode, undefined>
    displayName?: string
  },
  propsAreEqual?: PropsAreEqual<P> | false,

  componentName = component.displayName ?? component.name
): {
    (props: P): JSX.Element
    displayName: string
  } => {
  const WithListGroupItemStyle = (props: P): JSX.Element => {
    const ItemSettings = useContext(ItemContext)

    let stylesClasses = `${props.className} list-group-item`
    if (ItemSettings.actionList) {
      stylesClasses += ' list-group-item-action'
    }
    if (props.variant) {
      stylesClasses += ` list-group-item-${props.variant}`
    }
    if (props.active) {
      stylesClasses += ' active'
    }
    if (props.disabled) {
      stylesClasses += ' disabled'
    }

    return component({
      ...props,
      active: props.active ? props.active.toString() : '',
      'aria-current': props.active,
      className: stylesClasses
    }) as JSX.Element
  }

  WithListGroupItemStyle.displayName = `withListGroupItemStyle(${componentName})`

  const wrappedComponent = propsAreEqual === false ? WithListGroupItemStyle : React.memo(WithListGroupItemStyle, propsAreEqual)

  return wrappedComponent as typeof WithListGroupItemStyle
}

export default withListGroupItemStyle
