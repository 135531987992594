import { Container } from 'types'

interface Props extends Container {
  variant?: 'primary' | 'secondary' | 'info' | 'danger' | 'success' | 'light' | 'warning'
}

const Alert = ({ className = '', variant = 'primary', children, ...props }: Props): JSX.Element => {
  return (
    <div className={`alert alert-${variant} ${className}`} {...props}>
      {children}
    </div>
  )
}

export default Alert
