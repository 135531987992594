import { Icon } from 'types'

const StopIcon = ({ width = 18, height = 18, fill = '#595F69', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M0.840088 0.839844V17.1598H17.1601V0.839844H0.840088ZM1.80009 1.79984H16.2001V16.1998H1.80009V1.79984Z' fill={fill} />
    </svg>
  )
}

export default StopIcon
