import Alert from 'components/Alert'
import CircleMinusIcon from 'components/DynamicIcons/CircleMinus'
import CirclePlusIcon from 'components/DynamicIcons/CirclePlus'
import CustomSelect from 'components/Form/CustomSelect'
import ListGroup from 'components/ListGroup'
import ListGroupItemLink from 'components/ListGroup/Item/Link'
import { useApplications } from 'hooks/useApplications'
import { useCallback, useEffect, useState } from 'react'
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import { ApplicationSettings } from 'types'

const ApplicationVersions = (): JSX.Element => {
  const navigate = useNavigate()
  const { applicationId, versionId } = useParams()
  const { data } = useApplications()
  const [selectedApplication, selectApplication] = useState<ApplicationSettings>()

  const handleSelectApplication = useCallback((applicationId?: ApplicationSettings['id']) => {
    if (applicationId) {
      navigate(`/settings/application-versions/${applicationId}`)
    }
  }, [navigate])

  useEffect(() => {
    if (applicationId) {
      const newSelectedApplication = data.find((a) => a.id === applicationId)
      if (newSelectedApplication != null) {
        selectApplication(newSelectedApplication)
      }
    } else {
      selectApplication(data[0])
    }
  }, [applicationId, data])

  const removeApplication = useCallback(() => {
    if (confirm('Are you sure?')) {
      console.log('Removing application version...')
    }
  }, [])

  if (data.length === 0) {
    return (<Alert variant='warning'>You must create an Application first, <Link to='/settings/applications/new'>Go to create one</Link></Alert>)
  }

  return (
    <div className='data-app d-flex flex-grow-1 pt-5'>
      <div className='data-app__sidebar bg-white' style={{ minWidth: '240px' }}>
        <CustomSelect
          className='mb-2'
          aria-label='Application'
          onChange={(e) => handleSelectApplication(e.target.value)}
          options={data.map((a) => a.id)}
          emptyDefault={false}
        />

        {(selectedApplication != null) && selectedApplication.id && (
          <>
            <ListGroup actionList variant='flush'>
              {selectedApplication.visibleVersions.map((v) => (
                <ListGroupItemLink key={`application-version-${v}`} to={`/settings/application-versions/${selectedApplication.id}/versions/${v}`} className={versionId === v ? 'fw-semibold' : ''}>{v}</ListGroupItemLink>
              ))}
            </ListGroup>

            <div className='d-flex justify-content-end align-items-center mt-1'>
              <button type='button' className='btn btn-link pe-1' title='Remove' onClick={removeApplication}>
                <CircleMinusIcon />
              </button>
              <Link to={`/settings/application-versions/${selectedApplication.id}/versions/new`} className='btn btn-link ps-1' title='Add'>
                <CirclePlusIcon />
              </Link>
            </div>
          </>
        )}
      </div>

      <div className='px-5 w-100'>
        <Outlet />
      </div>
    </div>
  )
}

export default ApplicationVersions
