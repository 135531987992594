import { useEffect, useState } from 'react'
import ApplicationVersionService from 'services/application-versions'
import { ApplicationSettings, ApplicationVersion } from 'types'

export const useApplicationVersion = (applicationId: ApplicationSettings['id'], versionId: ApplicationVersion['id']): { data?: ApplicationVersion, error: Error | object, isLoading: boolean } => {
  const [data, setData] = useState<ApplicationVersion>()
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    ApplicationVersionService.findById<ApplicationVersion>(applicationId, versionId)
      .then((version) => {
        setData(version)
      })
      .catch((error: Error) => {
        setError(error)
        console.error(`Error getting version ${versionId}'s info: `, error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [applicationId, versionId, setData])

  return { data, error, isLoading }
}
