import { Icon } from 'types'

const CircleMinusIcon = ({ width = 30, height = 30, fill = '#58B0BE' }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.9998 0.279785C6.87729 0.279785 0.279785 6.87729 0.279785 14.9998C0.279785 23.1223 6.87729 29.7198 14.9998 29.7198C23.1223 29.7198 29.7198 23.1223 29.7198 14.9998C29.7198 6.87729 23.1223 0.279785 14.9998 0.279785ZM14.9998 1.55979C22.4298 1.55979 28.4398 7.56979 28.4398 14.9998C28.4398 22.4298 22.4298 28.4398 14.9998 28.4398C7.56979 28.4398 1.55979 22.4298 1.55979 14.9998C1.55979 7.56979 7.56979 1.55979 14.9998 1.55979ZM7.31979 14.3598V15.6398H22.6798V14.3598H7.31979Z' fill={fill} />
    </svg>
  )
}

export default CircleMinusIcon
