import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Container } from 'types'

import styles from './styles.module.scss'

const adjustVerticalPosition = (menu: HTMLElement, bottomOriginPoint: number) => {
  const pixelSnapping = 10
  let topPos = bottomOriginPoint

  const borderBottom = topPos + menu.clientHeight
  if (borderBottom >= window.innerHeight) {
    topPos = topPos - (borderBottom - window.innerHeight)
  }

  menu.style.top = `${topPos - pixelSnapping}px`
}

const adjustHorizontalPosition = (menu: HTMLElement, rightOriginPoint: number) => {
  let leftPos = rightOriginPoint
  const defaultMenuWidth = 224
  const menuWidth = menu.clientWidth > defaultMenuWidth ? menu.clientWidth : defaultMenuWidth

  const borderRight = leftPos + menuWidth
  if (borderRight >= window.innerWidth) {
    leftPos = Math.abs(leftPos - (borderRight - window.innerWidth) - 20)
  }

  menu.style.left = `${leftPos}px`
}

const updateMenuPosition = (menu: HTMLElement, elementForPositioning: HTMLElement) => {
  const boundingRect = elementForPositioning.getBoundingClientRect()
  adjustVerticalPosition(menu, boundingRect.bottom)
  adjustHorizontalPosition(menu, boundingRect.right)
}

interface Props extends Container {
  open: boolean
  triggerEl?: HTMLElement | null
  onClose: () => void
}

const FloatingMenu = ({ className = '', open = false, children, onClose, triggerEl, ...props }: Props): React.ReactPortal | null => {
  const modalPortal = document.getElementById('modal-portal')
  if (modalPortal == null) {
    return null
  }

  const menuEl = useRef(null)

  useEffect(() => {
    if (menuEl.current && (triggerEl != null)) {
      updateMenuPosition(menuEl.current, triggerEl)
    }
  }, [menuEl, triggerEl, open])

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div className={`${className} ${open ? styles.open : ''}`}>
      <div className={styles.backdrop} onClick={handleClose} />
      <div className={`floating-menu ${styles.floatingMenu}`} {...props} ref={menuEl}>
        {children}
      </div>
    </div>,
    modalPortal
  )
}

export default FloatingMenu
