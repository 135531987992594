import { Icon } from 'types'

const CloseCircleIcon = ({ width = 21, height = 21, fill = '#4e5c65' }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.96 0.959961C5.44196 0.959961 0.959961 5.44196 0.959961 10.96C0.959961 16.478 5.44196 20.96 10.96 20.96C16.478 20.96 20.96 16.478 20.96 10.96C20.96 5.44196 16.478 0.959961 10.96 0.959961ZM10.96 1.82953C16.0075 1.82953 20.0904 5.91241 20.0904 10.96C20.0904 16.0075 16.0075 20.0904 10.96 20.0904C5.91241 20.0904 1.82953 16.0075 1.82953 10.96C1.82953 5.91241 5.91241 1.82953 10.96 1.82953ZM14.4331 7.04182C14.3193 7.04352 14.2106 7.09107 14.1308 7.17429L10.96 10.3452L7.7891 7.17429C7.70758 7.08938 7.59549 7.04352 7.4783 7.04352C7.29997 7.04352 7.14203 7.15052 7.07409 7.31356C7.00785 7.4783 7.04692 7.66512 7.17429 7.7891L10.3452 10.96L7.17429 14.1308C7.0605 14.2395 7.01465 14.4026 7.05541 14.5537C7.09447 14.7066 7.21336 14.8254 7.36621 14.8645C7.51737 14.9053 7.68041 14.8594 7.7891 14.7456L10.96 11.5748L14.1308 14.7456C14.2395 14.8594 14.4026 14.9053 14.5537 14.8645C14.7066 14.8254 14.8254 14.7066 14.8645 14.5537C14.9053 14.4026 14.8594 14.2395 14.7456 14.1308L11.5748 10.96L14.7456 7.7891C14.8747 7.66512 14.9138 7.47321 14.8458 7.30847C14.7762 7.14372 14.6132 7.03673 14.4331 7.04182Z' fill={fill} />
    </svg>
  )
}

export default CloseCircleIcon
