import FilledSquarePlayIcon from 'components/DynamicIcons/FilledSquarePlay'
import SquarePlayIcon from 'components/DynamicIcons/SquarePlay'
import StopIcon from 'components/DynamicIcons/Stop'
import FloatingMenu from 'components/FloatingMenu'
import FloatingMenuItem from 'components/FloatingMenu/Item'
import FloatingMenuSeparator from 'components/FloatingMenu/Separator'

interface Props {
  open: boolean
  onClose: () => void
  onStart: () => void
  onStop: () => void
  onStartAs: () => void
  triggerEl?: HTMLElement | null
}

const AppCardFloatingMenu = ({ onStart, onStop, onStartAs, ...props }: Props): JSX.Element => {
  return (
    <FloatingMenu {...props}>
      <FloatingMenuItem onClick={onStart}>
        <SquarePlayIcon />
        <span>Start</span>
      </FloatingMenuItem>

      <FloatingMenuItem onClick={onStop}>
        <StopIcon />
        <span>Stop</span>
      </FloatingMenuItem>

      <FloatingMenuSeparator />

      <FloatingMenuItem onClick={onStartAs}>
        <FilledSquarePlayIcon />
        <span>Start As...</span>
      </FloatingMenuItem>
    </FloatingMenu>
  )
}

export default AppCardFloatingMenu
