import React from 'react'
import { ButtonVariant } from 'types'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
}

const Button = ({ className = '', type = 'button', variant = 'primary', children, ...props }: Props): JSX.Element => {
  return (
    <button type={type} className={`btn btn-${variant} ${className}`} {...props}>
      {children}
    </button>
  )
}

export default Button
