import React from 'react'
import { ButtonVariant } from 'types'

import HiddenFileInput from '../HiddenFileInput'

export interface ButtonUploaderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string
  name: string
  variant?: ButtonVariant
}

const ButtonUploader = ({ children, className = '', variant = 'primary', style = {}, ...props }: ButtonUploaderProps): JSX.Element => {
  const id = props.id ?? props.name

  return (
    <>
      <label htmlFor={id} className={`btn btn-${variant} ${className}`} style={style}>{children}</label>
      <HiddenFileInput id={id} {...props} />
    </>
  )
}

export default ButtonUploader
