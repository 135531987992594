import { createBrowserRouter } from 'react-router-dom'
import SettingsRoutes from 'routes/settings'
import ApplicationLayout from 'views/layouts/Application'
import NotFound from 'views/pages/NotFound'
import Workbench from 'views/pages/Workbench'

const router = createBrowserRouter([
  {
    path: '',
    element: <ApplicationLayout />,
    errorElement: <NotFound />,
    children: [
      { ...SettingsRoutes },
      {
        path: 'workbench',
        element: <Workbench />
      }
    ]
  }
])

export default router
