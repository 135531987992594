import { ApplicationSettings } from 'types'
import mockedApplications from 'mocks/applications'

class ApplicationService {
  static getAll = async <T>(): Promise<T> => {
    return await new Promise((resolve, reject) => {
      resolve(mockedApplications as T)
    })
  }

  static findById = async <T>(applicationId: ApplicationSettings['id']): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const foundApplication = mockedApplications.find((a) => a.id?.toString() === applicationId)
      resolve(foundApplication as T)
    })
  }
}

export default ApplicationService
