import withListGroupItemStyle from 'components/ListGroup/hoc/withListGroupItemStyle'
import { Link, LinkProps } from 'react-router-dom'
import { ListGroupItemProps } from 'types'

type Props = ListGroupItemProps & LinkProps

const ListGroupItemLink = (props: Props): JSX.Element => {
  return (
    <Link {...props} />
  )
}

export default withListGroupItemStyle<Props>(ListGroupItemLink)
