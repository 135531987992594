import InputErrorMessage from 'components/Form/InputErrorMessage'
import { Field, FieldHookConfig, useField } from 'formik'
import validateInput from 'validations/input'

interface Props {
  label?: string
}

const FormikInput = ({ label, className = '', ...props }: Props & FieldHookConfig<string>): JSX.Element => {
  const id = props.id ?? props.name
  const [field, meta] = useField(props)

  return (
    <>
      {label && (<label htmlFor={id} className='form-label'>{label}</label>)}
      <Field
        id={id}
        className={`form-control ${className} ${validateInput(meta.touched, meta.error)}`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (<InputErrorMessage error={meta.error} />)}
    </>
  )
}

export default FormikInput
