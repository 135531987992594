import React from 'react'
import styles from './styles.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
}

const CustomCheck = ({ label, className = '', ...props }: Props): JSX.Element => {
  const id = props.id ?? props.name

  return (
    <div className={`${styles.roundFormCheck} ${className}`}>
      <input
        id={id}
        type='checkbox'
        className={styles.roundCheckInput}
        {...props}
      />
      <label className={styles.roundCheckLabel} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default CustomCheck
