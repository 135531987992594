import EditIcon from 'components/DynamicIcons/Edit'
import RemoveIcon from 'components/DynamicIcons/Remove'
import useImageToBase64, { ValidExtension } from 'hooks/useImageToBase64'
import React from 'react'
import { ImageBase64 } from 'types'

import HiddenFileInput from '../HiddenFileInput'
import styles from './styles.module.scss'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  allowedExtensions?: ValidExtension[]
  error?: string
  name: string
  value?: ImageBase64
}

const ImageUploader = ({ accept, allowedExtensions = ['png'], className = '', name, onChange, value = '', ...props }: Props): JSX.Element => {
  const id = props.id ?? name
  const { image, handleChange, imagesAccepted, handleRemove } = useImageToBase64({ accept, allowedExtensions, onChange, name, value })

  return (
    <div className='d-flex flex-column'>
      <div className={`${styles.formImage} ${className}`}>
        <div className={styles.formImagePreview}>
          {image && <img src={image.toString()} alt='' />}
        </div>
        <label htmlFor={id} role='button' className={styles.formImageEdit}>
          <span title='Edit'>
            <EditIcon />
          </span>
        </label>
        <button type='button' className={styles.formImageRemove} onClick={handleRemove}>
          <span title='Remove'>
            <RemoveIcon />
          </span>
        </button>
      </div>
      <HiddenFileInput id={id} accept={imagesAccepted} onChange={handleChange} {...props} />
    </div>
  )
}

export default ImageUploader
