import useImageToBase64, { ValidExtension } from 'hooks/useImageToBase64'
import React from 'react'
import { ButtonVariant, ImageBase64 } from 'types'

import ButtonUploader, { ButtonUploaderProps } from '../ButtonUploader'
import styles from './styles.module.scss'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  allowedExtensions?: ValidExtension[]
  name: string
  value?: ImageBase64
  variant?: ButtonVariant
}

const ButtonImageUploader = ({ accept, allowedExtensions = ['png'], className = '', name, onChange, value = '', ...props }: Props & ButtonUploaderProps): JSX.Element => {
  const { image, handleChange, imagesAccepted } = useImageToBase64({ accept, allowedExtensions, onChange, name, value })

  return (
    <div className={`d-flex flex-column ${className}`}>
      <ButtonUploader accept={imagesAccepted} onChange={handleChange} name={name} {...props} />
      <div className={styles.imagePreview}>
        {image && <img src={image.toString()} alt='' />}
      </div>
    </div>
  )
}

export default ButtonImageUploader
