import { Outlet } from 'react-router-dom'
import { Breadcrumb } from 'types'
import TopBar from 'components/TopBar'
import Sidebar from 'components/Sidebar'
import styles from './styles.module.scss'

interface Props {
  className?: string
}

const breadcrumbs: Breadcrumb[] = [
  {
    path: '/',
    title: 'Default Organization'
  },
  {
    path: '#',
    title: 'Settings',
    active: true
  }
]

const Application = ({ className = '' }: Props): JSX.Element => {
  return (
    <div className={`${styles.applicationContainer} ${className}`}>
      <Sidebar />

      <main className='d-flex flex-column flex-grow-1'>
        {/* <!-- Topbar --> */}
        <TopBar breadcrumbs={breadcrumbs} />

        <Outlet />
      </main>
    </div>
  )
}

export default Application
