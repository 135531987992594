import Settings from 'views/pages/Settings'
import Applications from 'views/pages/Settings/Applications'
import EditApplication from 'views/pages/Settings/Applications/Edit'
import NewApplication from 'views/pages/Settings/Applications/New'
import ApplicationVersions from 'views/pages/Settings/ApplicationVersions'
import EditApplicationVersion from 'views/pages/Settings/ApplicationVersions/Edit'
import NewApplicationVersion from 'views/pages/Settings/ApplicationVersions/New'
import ApplicationVersionsVariant from 'views/pages/Settings/ApplicationVersionsVariant'
import EditApplicationVersionVariant from 'views/pages/Settings/ApplicationVersionsVariant/Edit'
import NewApplicationVersionVariant from 'views/pages/Settings/ApplicationVersionsVariant/New'
import Roles from 'views/pages/Settings/Roles'
import ServerInformation from 'views/pages/Settings/ServerInformation'

const SettingsRoutes = {
  path: 'settings',
  element: <Settings />,
  children: [
    {
      path: 'applications',
      element: <Applications />,
      children: [
        {
          path: 'new',
          element: <NewApplication />
        },
        {
          path: ':applicationId',
          element: <EditApplication />
        }
      ]
    },
    {
      path: 'application-versions',
      element: <ApplicationVersions />
    },
    {
      path: 'application-versions/:applicationId',
      element: <ApplicationVersions />
    },
    {
      path: 'application-versions/:applicationId/versions',
      element: <ApplicationVersions />,
      children: [
        {
          path: 'new',
          element: <NewApplicationVersion />
        },
        {
          path: ':versionId',
          element: <EditApplicationVersion />
        }
      ]
    },
    {
      path: 'application-versions-variant/:applicationId/versions',
      element: <ApplicationVersionsVariant />,
      children: [
        {
          path: 'new',
          element: <NewApplicationVersionVariant />
        },
        {
          path: ':versionId',
          element: <EditApplicationVersionVariant />
        }
      ]
    },
    {
      path: 'roles',
      element: <Roles />
    },
    {
      path: 'server-information/:applicationId',
      element: <ServerInformation />
    }
  ]
}

export default SettingsRoutes
