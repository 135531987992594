import React, { useCallback, useRef, useState } from 'react'
import CirclePlusIcon from 'components/DynamicIcons/CirclePlus'
import CloseCircleIcon from 'components/DynamicIcons/CloseCircle'
import Button from 'components/Button'
import styles from './styles.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  value?: string[]
}

const TagsInput = ({ label, className = '', size = 4, value = [], onChange, ...props }: Props): JSX.Element => {
  const id = props.id ?? props.name
  const [newTag, setNewTag] = useState<string>('')
  const tagInputElement = useRef(null)

  const emitUpdate = useCallback((newTags: string[]) => {
    if (onChange != null) {
      onChange({ target: { name: props.name, value: newTags } } as unknown as React.ChangeEvent<HTMLInputElement>)
    }
  }, [onChange])

  const addTag = useCallback(() => {
    if (newTag && value.findIndex((t) => t === newTag) < 0) {
      const newTags = [...value]
      newTags.push(newTag)
      emitUpdate(newTags)
      setNewTag('')
    }
  }, [value, newTag, emitUpdate, setNewTag])

  const removeTag = useCallback((index: number) => {
    const newTags = [...value]
    newTags.splice(index, 1)
    emitUpdate(newTags)
  }, [value, emitUpdate])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    setNewTag(target.value)
  }, [newTag, setNewTag])

  const focusInput = useCallback(() => {
    if (tagInputElement.current) {
      (tagInputElement.current as HTMLInputElement).focus()
    }
  }, [tagInputElement])

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      addTag()
    } else if (event.key === ',') {
      event.preventDefault()

      return false
    }
  }, [addTag])

  return (
    <>
      {label && (<label htmlFor={id} className='form-label'>{label}</label>)}
      <div className={`${styles.formTag} ${className}`}>
        <div className={styles.tagInput} onClick={focusInput}>
          {value.map((tag, i) => (
            <div key={tag} className={`${styles.tag} px-3`}>
              <span className='me-1'>{tag}</span>
              <button type='button' className={styles.tagRemove} title='Remove' onClick={() => removeTag(i)}>
                <CloseCircleIcon />
              </button>
            </div>
          ))}
          <input type='text' id={id} className={styles.internalInput} size={size} value={newTag} onChange={handleChange} onKeyDown={handleKeyDown} ref={tagInputElement} {...props} />
        </div>
        <Button variant='link' className={styles.addTag} title='Add' onClick={addTag}>
          <CirclePlusIcon />
        </Button>
      </div>
    </>
  )
}

export default TagsInput
