import { ButtonVariant, Container } from 'types'

import styles from './styles.module.scss'

interface Props extends Container {
  variant?: ButtonVariant
}

const Badge = ({ className = '', variant = 'primary', children, ...props }: Props): JSX.Element => {
  return (
    <span className={`badge ${styles.badge} ${styles[variant]} ${className}`} {...props}>
      {children}
    </span>
  )
}

export default Badge
