import { ValidExtension } from 'hooks/useImageToBase64'
import * as Yup from 'yup'
import { AnyObject } from 'yup/lib/types'

class ImageBase64Schema extends Yup.StringSchema<string | undefined, AnyObject, string | undefined> {
  allowedExtensions (extensions: ValidExtension[], message?: string) {
    return this.test(
      'allowed-extensions',
      message ?? 'Image format NOT supported',
      (value) => {
        if (value && (value.match(new RegExp(`^data:image/(${extensions.join('|')});`, 'i')) == null)) {
          return false
        }

        return true
      }
    )
  }

  maxResolution ({ maxHeight, maxWidth }: { maxHeight?: number, maxWidth?: number }, message?: string) {
    return this.test(
      'maximum-resolution',
      message ?? this.buildMaxResolutionMessage({ maxHeight, maxWidth }),
      (value) => {
        if (value) {
          let imageContainer = document.getElementById('tmp-image-container')
          if (imageContainer == null) {
            imageContainer = document.createElement('div')
            imageContainer.id = 'tmp-image-container'
            imageContainer.style.position = 'fixed'
            imageContainer.style.top = '-100%'
            imageContainer.style.left = '-100%'
            imageContainer.style.zIndex = '-1'
            imageContainer.style.opacity = '0'
            document.querySelector('body')?.appendChild(imageContainer)
          }

          imageContainer.innerHTML = `<img src='${value}' alt='' />`
          const image = imageContainer.querySelector('img')
          if ((image != null) && ((maxHeight && image.clientHeight > maxHeight) || (maxWidth && image.clientHeight > maxWidth))) {
            return false
          }
        }

        return true
      }
    )
  }

  private buildMaxResolutionMessage ({ maxHeight, maxWidth }: { maxHeight?: number, maxWidth?: number }) {
    if (maxHeight && maxWidth) {
      return `The image must be a maximum of ${maxHeight}px high by ${maxWidth}px wide`
    }

    if (maxHeight) {
      return `The image must be a maximum of ${maxHeight}px high`
    }

    if (maxWidth) {
      return `The image must be a maximum of ${maxWidth}px wide`
    }

    return ''
  }
}

export default () => new ImageBase64Schema()
