import Button from 'components/Button'
import ButtonImageUploader from 'components/Form/ButtonImageUploader'
import FormikInput from 'components/Form/Formik/Input'
import FormikSelect from 'components/Form/Formik/Select'
import ImageUploader from 'components/Form/ImageUploader'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { ApplicationVersion } from 'types'
import ImageBase64 from 'validations/image'
import ResourceLimitsValidations from 'validations/resource-limits'
import * as Yup from 'yup'

import ProfilesInput from './Profiles'

const initValues = (options?: Partial<ApplicationVersion>): ApplicationVersion => {
  const defaults = {
    id: '',
    title: '',
    description: '',
    icon: {},
    profiles: []
  }

  return { ...defaults, ...options }
}

const validationSchema = Yup.object({
  id: Yup.string()
    .matches(/^[0-9]+(\.[0-9]+)+$/i, 'Invalid format.')
    .required('Required'),
  title: Yup.string()
    .required('Required'),
  description: Yup.string(),
  icon: Yup.object().shape({
    kind: Yup.string(),
    link: Yup.string(),
    file: ImageBase64()
      .allowedExtensions(['png'])
      .maxResolution({
        maxHeight: 128,
        maxWidth: 128
      })
  }),
  profiles: Yup.array().of(
    ResourceLimitsValidations({
      name: Yup.string()
        .required('Required')
    })
  )
})

interface Props {
  initApplicationVersionValues?: Partial<ApplicationVersion>
  variant?: 'default' | 'button-uploader'
}

const ApplicationVersionForm = ({ initApplicationVersionValues, variant = 'default' }: Props): JSX.Element => {
  const handleSubmit = useCallback((values: ApplicationVersion) => {
    alert(JSON.stringify(values, null, 2))
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={initValues(initApplicationVersionValues)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form className='needs-validation'>
          <FormikInput type='hidden' name='applicationId' />

          <div className='row pe-0 pe-3 me-0 me-5'>
            <div className='col-12 col-md-4'>
              <div className='mb-3'>
                <FormikInput type='text' name='id' label='Version' />
              </div>
            </div>

            <div className='col-12 col-md-8'>
              <div className='mb-3'>
                <FormikInput type='text' name='title' label='Title' />
              </div>
            </div>
          </div>

          <div className='row pe-0 pe-3 me-0 me-5'>
            <div className='col-12 mb-3'>
              <FormikInput as='textarea' name='description' label='Description' />
            </div>
          </div>

          <div className='row pe-0 pe-3 me-0 me-5'>
            <div className='col-12 col-lg-4 mb-3'>
              <div className='d-flex'>
                <span className='mt-2 me-1 me-md-4'>Icon</span>
                {variant === 'button-uploader'
                  ? <ButtonImageUploader {...formik.getFieldProps('icon.file')} style={{ minWidth: '120px' }} error={formik.errors.icon?.file}>+ Icon</ButtonImageUploader>
                  : <ImageUploader {...formik.getFieldProps('icon.file')} error={formik.errors.icon?.file} />}
              </div>
            </div>

            <div className='col-12 col-lg-8'>
              <div className='row mb-3'>
                <label htmlFor='icon.kind' className='col-3 col-md-2 col-form-label'>Kind</label>
                <div className='col-8 col-md-10'>
                  <FormikSelect
                    name='icon.kind'
                    options={['Native', 'Other']}
                    aria-label='Kind'
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <label htmlFor='icon.link' className='col-3 col-md-2 col-form-label'>Link</label>
                <div className='col-8 col-md-10'>
                  <FormikInput name='icon.link' type='url' />
                </div>
              </div>
            </div>
          </div>

          <h6>Profiles</h6>

          <div className='pe-0 pe-3 me-0 me-5'>
            <hr />
          </div>

          <ProfilesInput profiles={formik.values.profiles} />

          <div className='my-4 pe-0 pe-3 me-0 me-5'>
            <hr />
          </div>

          <div className='d-flex justify-content-end mb-2'>
            <Button variant='light'>Cancel</Button>
            <Button type='submit' className='ms-0 ms-lg-3'>Save</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ApplicationVersionForm
