import InputErrorMessage from '../InputErrorMessage'
import styles from './styles.module.scss'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  error?: string
}

const HiddenFileInput = ({ error, ...props }: Props) => {
  return (
    <>
      <input type='file' className={`${styles.hiddenFileInput} ${error ? 'is-invalid' : ''}`} {...props} />
      <InputErrorMessage error={error} />
    </>
  )
}

export default HiddenFileInput
