import ApplicationForm from 'components/Application/Form'
import { useApplication } from 'hooks/useApplication'

const NewApplication = () => {
  const { data } = useApplication()

  return (
    <div className='max-w-700'>
      <h5 className='fs-18'>Applications</h5>

      <hr className='my-4' />

      <ApplicationForm initApplicationValues={data} />
    </div>
  )
}

export default NewApplication
