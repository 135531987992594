import { Icon } from 'types'

const CirclePlusIcon = ({ width = 30, height = 30, fill = '#58B0BE' }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.9998 0.279785C6.87773 0.279785 0.279785 6.87773 0.279785 14.9998C0.279785 23.1218 6.87773 29.7198 14.9998 29.7198C23.1218 29.7198 29.7198 23.1218 29.7198 14.9998C29.7198 6.87773 23.1218 0.279785 14.9998 0.279785ZM14.9998 1.55979C22.4301 1.55979 28.4398 7.5695 28.4398 14.9998C28.4398 22.4301 22.4301 28.4398 14.9998 28.4398C7.5695 28.4398 1.55979 22.4301 1.55979 14.9998C1.55979 7.5695 7.5695 1.55979 14.9998 1.55979ZM14.3598 7.31979V14.3598H7.31979V15.6398H14.3598V22.6798H15.6398V15.6398H22.6798V14.3598H15.6398V7.31979H14.3598Z' fill={fill} />
    </svg>
  )
}

export default CirclePlusIcon
