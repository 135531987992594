import CircleMinusIcon from 'components/DynamicIcons/CircleMinus'
import CirclePlusIcon from 'components/DynamicIcons/CirclePlus'
import ListGroup from 'components/ListGroup'
import ListGroupItemLink from 'components/ListGroup/Item/Link'
import { useApplications } from 'hooks/useApplications'
import { useCallback } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'

const Applications = (): JSX.Element => {
  const { applicationId } = useParams()
  const { data } = useApplications()

  const removeApplication = useCallback(() => {
    if (confirm('Are you sure?')) {
      console.log('Removing application...')
    }
  }, [])

  return (
    <div className='data-app d-flex flex-grow-1 pt-5'>
      <div className='data-app__sidebar bg-white' style={{ minWidth: '240px' }}>
        <ListGroup actionList variant='flush'>
          {data.map((app) => (
            <ListGroupItemLink key={`application-${app.id}`} to={`/settings/applications/${app.id}`} className={applicationId === app.id ? 'fw-semibold' : ''}>{app.id}</ListGroupItemLink>
          ))}
        </ListGroup>

        <div className='d-flex justify-content-end align-items-center mt-1'>
          <button type='button' className='btn btn-link pe-1' title='Remove' onClick={removeApplication}>
            <CircleMinusIcon />
          </button>
          <Link to='/settings/applications/new' className='btn btn-link ps-1' title='Add'>
            <CirclePlusIcon />
          </Link>
        </div>
      </div>

      <div className='px-5 w-100'>
        <Outlet />
      </div>
    </div>
  )
}

export default Applications
