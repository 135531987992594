import { ApplicationSettings } from 'types'

const mockedApplications: ApplicationSettings[] = [
  {
    id: 'com.enthought.foo',
    visibleVersions: ['1.4.5', '5.4.5'],
    autoAddNewVersions: true,
    limits: {
      instance: {
        cpu: 2,
        gpu: 2,
        memory: 3,
        shutdown: ''
      },
      global: {
        cpu: 1,
        gpu: 2,
        memory: 5,
        shutdown: ''
      }
    }
  },
  {
    id: 'com.enthought.bar',
    visibleVersions: ['1.2.5', '3.4.5'],
    autoAddNewVersions: false,
    limits: {
      instance: {
        cpu: 1,
        gpu: 0,
        memory: 3,
        shutdown: ''
      },
      global: {
        cpu: 1,
        gpu: 2,
        memory: 5,
        shutdown: ''
      }
    }
  },
  {
    id: 'com.enthought.baz',
    visibleVersions: ['2.4.5', '4.4.5'],
    autoAddNewVersions: true,
    limits: {
      instance: {
        cpu: 4,
        gpu: 1,
        memory: 5,
        shutdown: ''
      },
      global: {
        cpu: 6,
        gpu: 0,
        memory: 5,
        shutdown: ''
      }
    }
  }
]

export default mockedApplications
