import { useParams } from 'react-router-dom'
import { useApplicationVersion } from 'hooks/useApplicationVersion'
import ApplicationVersionForm from 'components/ApplicationVersion/Form'
import Alert from 'components/Alert'
import { ApplicationVersion } from 'types'

const EditApplicationVersion = (): JSX.Element => {
  const { applicationId, versionId } = useParams()

  let data: ApplicationVersion | undefined
  if (applicationId && versionId) {
    const applicationVersion = useApplicationVersion(applicationId, versionId)
    data = applicationVersion.data
  }

  return (
    <div className='max-w-750'>
      <h5 className='fs-18'>Application Versions</h5>

      <hr className='my-4' />

      {(data != null)
        ? <ApplicationVersionForm initApplicationVersionValues={data} variant='button-uploader' />
        : <Alert variant='warning'>Application Version NOT Found!</Alert>}
    </div>
  )
}

export default EditApplicationVersion
