import Button from 'components/Button'
import CustomCheck from 'components/Form/CustomCheck'
import FormikInput from 'components/Form/Formik/Input'
import TagsInput from 'components/Form/TagsInput'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { ApplicationSettings } from 'types'
import domainValidation from 'validations/domain'
import ResourceLimitsValidations from 'validations/resource-limits'
import * as Yup from 'yup'

const validationSchema = Yup.object({
  id: domainValidation()
    .required('Required'),
  limits: Yup.object().shape({
    instance: ResourceLimitsValidations(),
    global: ResourceLimitsValidations()
  })
})

interface Props {
  initApplicationValues: ApplicationSettings
}

const ApplicationForm = ({ initApplicationValues }: Props): JSX.Element => {
  const handleSubmit = useCallback((values: ApplicationSettings) => {
    alert(JSON.stringify(values, null, 2))
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={initApplicationValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form className='needs-validation'>
          <div className='mb-3'>
            <FormikInput
              name='id'
              type='url'
              label='App D'
              readOnly={initApplicationValues.id !== undefined && initApplicationValues.id !== ''}
            />
          </div>

          <div className='mb-3'>
            <TagsInput
              label='Visible Versions'
              {...formik.getFieldProps('visibleVersions')}
            />
          </div>

          <hr className='my-4' />

          <CustomCheck
            name='autoAddNewVersions'
            label='Auto-add New Versions'
            className='mb-4'
            checked={formik.values.autoAddNewVersions}
            onChange={formik.handleChange}
          />

          <h6>Resource Limits</h6>

          <div className='table-responsive'>
            <table className='table table-borderless table-narrow'>
              <thead>
                <tr>
                  <th style={{ minWidth: '110px' }} />
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>CPU</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>GPU</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>Memory (GB)</th>
                  <th style={{ minWidth: '135px' }} className='fw-medium text-center'>Shutdown</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className='fw-medium align-middle'>Per Instance</th>
                  <td>
                    <FormikInput
                      name='limits.instance.cpu'
                      className='text-start text-md-center'
                      type='number'
                    />
                  </td>
                  <td>
                    <FormikInput
                      name='limits.instance.gpu'
                      className='text-start text-md-center'
                      type='number'
                    />
                  </td>
                  <td>
                    <FormikInput
                      name='limits.instance.memory'
                      className='text-start text-md-center'
                      type='number'
                    />
                  </td>
                  <td>
                    <FormikInput
                      name='limits.instance.shutdown'
                      className='text-start text-md-center'
                      type='text'
                    />
                  </td>
                </tr>
                <tr>
                  <th className='fw-medium align-middle'>Global</th>
                  <td>
                    <FormikInput
                      name='limits.global.cpu'
                      className='text-start text-md-center'
                      type='number'
                    />
                  </td>
                  <td>
                    <FormikInput
                      name='limits.global.gpu'
                      className='text-start text-md-center'
                      type='number'
                    />
                  </td>
                  <td>
                    <FormikInput
                      name='limits.global.memory'
                      className='text-start text-md-center'
                      type='number'
                    />
                  </td>
                  <td>
                    <FormikInput
                      name='limits.global.shutdown'
                      className='text-start text-md-center'
                      type='text'
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr className='my-4' />

          <div className='d-flex justify-content-end mb-2'>
            <Button variant='light'>Cancel</Button>
            <Button type='submit' className='ms-0 ms-lg-3'>Save</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ApplicationForm
