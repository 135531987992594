import withListGroupItemStyle from 'components/ListGroup/hoc/withListGroupItemStyle'
import { ListGroupItemProps } from 'types'

type Props = ListGroupItemProps & React.AnchorHTMLAttributes<HTMLAnchorElement>

const ListGroupItemAnchor = (props: Props): JSX.Element => {
  return (
    <a {...props} />
  )
}

export default withListGroupItemStyle<Props>(ListGroupItemAnchor)
