import { createContext } from 'react'
import { Breakpoint, Container } from 'types'

interface Props extends Container {
  variant?: 'flush' | 'horizontal' | 'numbered'
  horizontalVariant?: Breakpoint
  actionList?: boolean
}

export const ItemContext = createContext<{ actionList?: Props['actionList'], variant?: Props['variant'] }>({})

const ListGroup = ({ actionList, className, children, horizontalVariant, variant, ...props }: Props): JSX.Element => {
  let classes = `${className} list-group`
  if (variant === 'horizontal' && horizontalVariant) {
    classes += ` list-group-horizontal-${horizontalVariant}`
  } else if (variant) {
    classes += ` list-group-${variant}`
  }

  return (
    <ItemContext.Provider value={{ actionList, variant }}>
      <div className={classes} {...props}>{children}</div>
    </ItemContext.Provider>
  )
}

export default ListGroup
