import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

interface IconProps {
  icon?: string | React.ReactNode
  alt?: string
}

const Icon = ({ icon, alt }: IconProps): JSX.Element => {
  if (icon === undefined && alt !== undefined) {
    return <span>{alt}</span>
  }

  if (typeof icon === 'string') {
    return <img src={icon} alt={alt} />
  }

  return <>{icon}</>
}

interface Props {
  path: string
  title?: string
  icon?: string | React.ReactNode
  active?: boolean
}

const SidebarItem = ({ path, icon, title, active = false }: Props): JSX.Element => {
  return (
    <div className={`${styles.applicationSidebarItem} ${active ? styles.active : ''}`}>
      <Link to={path} title={title}>
        <Icon icon={icon} alt={title} />
      </Link>
    </div>
  )
}

export default SidebarItem
