import { Link } from 'react-router-dom'
import BreadcrumbsBar from 'components/BreadcrumbsBar'
import SettingsIcon from 'components/DynamicIcons/Settings'
import { Breadcrumb } from 'types'
import MenuItem from './MenuItem'
import ProfileFloatingMenu from './ProfileFloatingMenu'
import styles from './styles.module.scss'
import NotificationsMenu from './NotificationsMenu'

interface Props {
  breadcrumbs: Breadcrumb[]
}

const TopBar = ({ breadcrumbs }: Props): JSX.Element => {
  return (
    <div className={`${styles.applicationTopBar} bg-white d-flex align-items-center justify-content-between`}>
      <BreadcrumbsBar breadcrumbs={breadcrumbs} />

      <div className='d-flex align-items-center'>
        <NotificationsMenu />
        <MenuItem>
          <Link to='/settings/applications'>
            <SettingsIcon />
          </Link>
        </MenuItem>
        <ProfileFloatingMenu />
      </div>
    </div>
  )
}

export default TopBar
