import { Icon } from 'types'

const FilledSquarePlayIcon = ({ width = 18, height = 18, fill = '#595F69', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M15.4001 0.600098H2.6001C1.49541 0.600098 0.600098 1.49541 0.600098 2.6001V15.4001C0.600098 16.5048 1.49541 17.4001 2.6001 17.4001H15.4001C16.5048 17.4001 17.4001 16.5048 17.4001 15.4001V2.6001C17.4001 1.49541 16.5048 0.600098 15.4001 0.600098ZM6.2001 13.4001V4.6001L13.4001 9.0001L6.2001 13.4001Z' fill={fill} />
    </svg>
  )
}

export default FilledSquarePlayIcon
