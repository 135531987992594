import { ApplicationSettings, ApplicationVersion } from 'types'
import mockedApplicationVersions from 'mocks/application-versions'

class ApplicationVersionService {
  static getAll = async <T>(applicationId: ApplicationSettings['id']): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const applicationVersions = mockedApplicationVersions.filter((av) => av.applicationId?.toString() === applicationId)
      resolve(applicationVersions as T)
    })
  }

  static findById = async <T>(applicationId: ApplicationSettings['id'], versionId: ApplicationVersion['id']): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const foundApplicationVersion = mockedApplicationVersions.find((av) => av.applicationId?.toString() === applicationId && av.id?.toString() === versionId)
      if (foundApplicationVersion != null) {
        resolve(foundApplicationVersion as T)
      } else {
        reject('Not Found')
      }
    })
  }
}

export default ApplicationVersionService
