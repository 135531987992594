import { Container } from 'types'
import styles from './styles.module.scss'

const MenuItem = ({ children, className = '' }: Container): JSX.Element => {
  return (
    <div className={`${styles.applicationTopBarMenuItem} ${className}`}>
      {children}
    </div>
  )
}

export default MenuItem
