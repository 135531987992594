import { useEffect, useState } from 'react'
import ServerService from 'services/servers'
import { ApplicationSettings, Server } from 'types'

export const useServers = (applicationId?: ApplicationSettings['id']): [data: Server[], setData: React.Dispatch<React.SetStateAction<Server[]>>, error: Error | object, isLoading: boolean ] => {
  const [data, setData] = useState<Server[]>([])
  const [error, setError] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (applicationId) {
      setIsLoading(true)
      ServerService.getAll<Server[]>(applicationId)
        .then((data) => {
          setData(data)
        })
        .catch((error: Error) => {
          setError(error)
          console.error('Error getting Servers info: ', error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  return [data, setData, error, isLoading]
}
