import { Icon } from 'types'

const SquarePlayIcon = ({ width = 18, height = 18, fill = '#595F69', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M0.999951 0.200195C0.562451 0.200195 0.199951 0.562695 0.199951 1.0002V17.0002C0.199951 17.4377 0.562451 17.8002 0.999951 17.8002H17C17.4375 17.8002 17.8 17.4377 17.8 17.0002V1.0002C17.8 0.562695 17.4375 0.200195 17 0.200195H0.999951ZM0.999951 1.0002H17V17.0002H0.999951V1.0002ZM5.79995 4.3002V13.7002L6.39995 13.3502L13.2 9.3502L13.7875 9.0002L13.2 8.6502L6.39995 4.6502L5.79995 4.3002ZM6.59995 5.7002L12.2 9.0002L6.59995 12.3002V5.7002Z' fill={fill} />
    </svg>
  )
}

export default SquarePlayIcon
