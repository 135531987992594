import { useParams } from 'react-router-dom'
import ApplicationForm from 'components/Application/Form'
import { useApplication } from 'hooks/useApplication'

const EditApplication = () => {
  const { applicationId } = useParams()
  const { data } = useApplication(applicationId)

  return (
    <div className='max-w-700'>
      <h5 className='fs-18'>Applications</h5>

      <hr className='my-4' />

      <ApplicationForm initApplicationValues={data} />
    </div>
  )
}

export default EditApplication
