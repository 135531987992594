import { Icon } from 'types'

const SmallTrashIcon = ({ width = 20, height = 20, fill = '#FD7972', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.04999 3.70503C8.18127 3.57375 8.35932 3.5 8.54497 3.5H11.445C11.6306 3.5 11.8087 3.57375 11.9399 3.70503C12.0712 3.8363 12.145 4.01435 12.145 4.2V4.9L7.84497 4.89999V4.2C7.84497 4.01435 7.91872 3.8363 8.04999 3.70503ZM6.34497 4.89999V4.2C6.34497 3.61652 6.57675 3.05694 6.98933 2.64437C7.40191 2.23179 7.96149 2 8.54497 2H11.445C12.0284 2 12.588 2.23179 13.0006 2.64437C13.4132 3.05694 13.645 3.61652 13.645 4.2V4.9L16.52 4.89999C16.9342 4.89999 17.27 5.23578 17.27 5.64999C17.27 6.06421 16.9342 6.39999 16.52 6.39999H15.82V15.8C15.82 16.3835 15.5882 16.9431 15.1756 17.3556C14.763 17.7682 14.2034 18 13.62 18H6.36997C5.78649 18 5.22691 17.7682 4.81433 17.3556C4.40175 16.9431 4.16997 16.3835 4.16997 15.8V6.39999H3.46997C3.05576 6.39999 2.71997 6.06421 2.71997 5.64999C2.71997 5.23578 3.05576 4.89999 3.46997 4.89999H6.34497ZM5.66997 15.8V6.4H14.32V15.8C14.32 15.9857 14.2462 16.1637 14.1149 16.295C13.9837 16.4263 13.8056 16.5 13.62 16.5H6.36997C6.18432 16.5 6.00627 16.4263 5.87499 16.295C5.74372 16.1637 5.66997 15.9857 5.66997 15.8ZM8.54498 8.52502C8.9592 8.52502 9.29498 8.86081 9.29498 9.27502V13.625C9.29498 14.0392 8.9592 14.375 8.54498 14.375C8.13077 14.375 7.79498 14.0392 7.79498 13.625V9.27502C7.79498 8.86081 8.13077 8.52502 8.54498 8.52502ZM12.1949 9.27502C12.1949 8.86081 11.8592 8.52502 11.4449 8.52502C11.0307 8.52502 10.6949 8.86081 10.6949 9.27502V13.625C10.6949 14.0392 11.0307 14.375 11.4449 14.375C11.8592 14.375 12.1949 14.0392 12.1949 13.625V9.27502Z' fill={fill} />
    </svg>
  )
}

export default SmallTrashIcon
