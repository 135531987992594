import { useParams } from 'react-router-dom'
import ApplicationVersionForm from 'components/ApplicationVersion/Form'

const NewApplicationVersion = (): JSX.Element => {
  const { applicationId } = useParams()

  return (
    <div className='max-w-750'>
      <h5 className='fs-18'>Application Versions</h5>

      <hr className='my-4' />

      <ApplicationVersionForm initApplicationVersionValues={{ applicationId }} />
    </div>
  )
}

export default NewApplicationVersion
