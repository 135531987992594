import Badge from 'components/Badge'
import VerticalDotsIcon from 'components/DynamicIcons/VerticalDots'
import Modal from 'components/Modal'
import ProfilePicker from 'components/Profile/Picker'
import { useCallback, useRef, useState } from 'react'
import { App, ApplicationProfile } from 'types'

import AppCardFloatingMenu from './FloatingMenu'
import styles from './styles.module.scss'

const profiles: ApplicationProfile[] = [
  {
    name: 'Small',
    cpu: 2,
    gpu: 0,
    memory: 2,
    shutdown: '',
    url: '#'
  },
  {
    name: 'Medium',
    cpu: 5,
    gpu: 1,
    memory: 3,
    shutdown: '',
    url: '#'
  },
  {
    name: 'Large',
    cpu: 16,
    gpu: 1,
    memory: 8,
    shutdown: '',
    url: '#'
  }
]

interface Props {
  app: App
  className?: string
  id?: string
}

const AppCard = ({ app, className, id }: Props): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const menuToggleEl = useRef(null)

  const statusColor = () => {
    switch (app.status) {
      case 'Running':
        return 'success'
      case 'Starting':
        return 'info'
      case 'Stopped':
        return 'danger'
      case 'Stopping':
        return 'warning'
      default:
        return 'primary'
    }
  }

  const handleToggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen, setMenuOpen])

  const handleStart = () => {
    console.log('starting...')
  }

  const handleStartAs = () => {
    setShowModal(true)
    setMenuOpen(false)
  }

  const handleStop = () => {
    console.log('stopping...')
  }

  const handleModalCancel = () => {
    setTimeout(() => setShowModal(false), 200)
  }

  const handleFloatingMenuClose = useCallback(() => {
    setMenuOpen(false)
  }, [setMenuOpen])

  return (
    <div id={id} className={`card ${styles.AppCard} ${className}`}>
      <div className={`card-header ${styles.header}`}>
        <Badge variant={statusColor()}>{app.status}</Badge>

        <div>
          <button
            type='button'
            className={`w-100 text-center px-2 ${styles.floatingMenuTrigger}`}
            title='See Menu'
            onClick={handleToggleMenu}
            ref={menuToggleEl}
          >
            <VerticalDotsIcon />
          </button>

          <AppCardFloatingMenu
            open={menuOpen}
            onStart={handleStart}
            onStartAs={handleStartAs}
            onStop={handleStop}
            onClose={handleFloatingMenuClose}
            triggerEl={menuToggleEl.current}
          />
        </div>
      </div>
      <div className={`card-body ${styles.body}`}>
        <a href={app.url} target='_blank' rel='noopener noreferrer'>
          {app.icon && <img src={app.icon} alt='' />}
          <h5>{app.name}</h5>
        </a>
      </div>

      <Modal
        showModal={showModal}
        onClose={handleModalCancel}
        title={<span className='d-block text-center'>Select Compute Profile</span>}
        className='modal-600'
      >
        <ProfilePicker profiles={profiles} />
      </Modal>
    </div>
  )
}

export default AppCard
