import * as Yup from 'yup'

const createValidations = (customRules = {}) => Yup.object().shape(
  Object.assign(
    {},
    {
      cpu: Yup.number()
        .min(1, 'Must be equal or greater than 1')
        .integer()
        .required('Required'),
      gpu: Yup.number()
        .min(0, 'Must be equal or greater than 0')
        .integer()
        .required('Required'),
      memory: Yup.number()
        .min(1, 'Must be equal or greater than 1')
        .integer()
        .required('Required'),
      shutdown: Yup.string()
    },
    customRules)
)

export default createValidations
