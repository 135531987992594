import mockedServers from 'mocks/servers'
import { ApplicationSettings, Server } from 'types'

class ServerService {
  static getAll = async <T>(applicationId: ApplicationSettings['id']): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const filteredServers = mockedServers.filter((s) => s.applicationId === applicationId)
      resolve(filteredServers as T)
    })
  }

  static findById = async <T>(applicationId: ApplicationSettings['id'], serverId: Server['id']): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const foundServer = mockedServers.find((s) => s.applicationId === applicationId && s.id === serverId)
      if (foundServer != null) {
        resolve(foundServer as T)
      } else {
        reject('Not Found')
      }
    })
  }
}

export default ServerService
