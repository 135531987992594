import { Icon } from 'types'

const VerticalDotsIcon = ({ width = 4, height = 20, fill = '#8A9099', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 4 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M2.00005 15.85C3.077 15.85 3.95005 16.723 3.95005 17.8C3.95005 18.877 3.077 19.75 2.00005 19.75C0.923093 19.75 0.0500488 18.877 0.0500488 17.8C0.0500488 16.723 0.923093 15.85 2.00005 15.85ZM2.00005 8.05C3.077 8.05 3.95005 8.92305 3.95005 10C3.95005 11.077 3.077 11.95 2.00005 11.95C0.923093 11.95 0.0500488 11.077 0.0500488 10C0.0500488 8.92305 0.923093 8.05 2.00005 8.05ZM3.95005 2.2C3.95005 1.12304 3.077 0.25 2.00005 0.25C0.923093 0.25 0.0500488 1.12304 0.0500488 2.2C0.0500488 3.27696 0.923093 4.15 2.00005 4.15C3.077 4.15 3.95005 3.27696 3.95005 2.2Z' fill={fill} />
    </svg>
  )
}

export default VerticalDotsIcon
