import { App } from 'types'

const mockedApps: App[] = [
  {
    id: 1,
    name: 'Data Analysis',
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSI1MHB4IiBoZWlnaHQ9IjUwcHgiPgo8ZyBpZD0ic3VyZmFjZTg1NTU1Ij4KPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDk1LjI5NDExOCUsNDUuNDkwMTk2JSwyNy44NDMxMzklKTtmaWxsLW9wYWNpdHk6MTtzdHJva2Utd2lkdGg6My40NDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlOnJnYig5NS4yOTQxMTglLDQ1LjQ5MDE5NiUsMjcuODQzMTM5JSk7c3Ryb2tlLW9wYWNpdHk6MTtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGQ9Ik0gMTUwLjUgNzcuNCBMIDk0LjYgNzcuNCBMIDk0LjYgMjEuNSBMIDc3LjQgMjEuNSBMIDc3LjQgNzcuNCBMIDIxLjUgNzcuNCBMIDIxLjUgOTQuNiBMIDc3LjQgOTQuNiBMIDc3LjQgMTUwLjUgTCA5NC42IDE1MC41IEwgOTQuNiA5NC42IEwgMTUwLjUgOTQuNiBaIE0gMTUwLjUgNzcuNCAiIHRyYW5zZm9ybT0ibWF0cml4KDAuMjkwNjk4LDAsMCwwLjI5MDY5OCwwLDApIi8+CjwvZz4KPC9zdmc+Cg==',
    status: 'Running',
    url: 'https://www.google.com'
  },
  {
    id: 2,
    name: 'Data Explorer',
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSI1MHB4IiBoZWlnaHQ9IjUwcHgiPgo8ZyBpZD0ic3VyZmFjZTg1NTU1Ij4KPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDk1LjI5NDExOCUsNDUuNDkwMTk2JSwyNy44NDMxMzklKTtmaWxsLW9wYWNpdHk6MTtzdHJva2Utd2lkdGg6My40NDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlOnJnYig5NS4yOTQxMTglLDQ1LjQ5MDE5NiUsMjcuODQzMTM5JSk7c3Ryb2tlLW9wYWNpdHk6MTtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGQ9Ik0gMTUwLjUgNzcuNCBMIDk0LjYgNzcuNCBMIDk0LjYgMjEuNSBMIDc3LjQgMjEuNSBMIDc3LjQgNzcuNCBMIDIxLjUgNzcuNCBMIDIxLjUgOTQuNiBMIDc3LjQgOTQuNiBMIDc3LjQgMTUwLjUgTCA5NC42IDE1MC41IEwgOTQuNiA5NC42IEwgMTUwLjUgOTQuNiBaIE0gMTUwLjUgNzcuNCAiIHRyYW5zZm9ybT0ibWF0cml4KDAuMjkwNjk4LDAsMCwwLjI5MDY5OCwwLDApIi8+CjwvZz4KPC9zdmc+Cg==',
    status: 'Running',
    url: 'https://www.google.com'
  },
  {
    id: 3,
    name: 'Scientific Web App',
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSI1MHB4IiBoZWlnaHQ9IjUwcHgiPgo8ZyBpZD0ic3VyZmFjZTg1NTU1Ij4KPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDk1LjI5NDExOCUsNDUuNDkwMTk2JSwyNy44NDMxMzklKTtmaWxsLW9wYWNpdHk6MTtzdHJva2Utd2lkdGg6My40NDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlOnJnYig5NS4yOTQxMTglLDQ1LjQ5MDE5NiUsMjcuODQzMTM5JSk7c3Ryb2tlLW9wYWNpdHk6MTtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGQ9Ik0gMTUwLjUgNzcuNCBMIDk0LjYgNzcuNCBMIDk0LjYgMjEuNSBMIDc3LjQgMjEuNSBMIDc3LjQgNzcuNCBMIDIxLjUgNzcuNCBMIDIxLjUgOTQuNiBMIDc3LjQgOTQuNiBMIDc3LjQgMTUwLjUgTCA5NC42IDE1MC41IEwgOTQuNiA5NC42IEwgMTUwLjUgOTQuNiBaIE0gMTUwLjUgNzcuNCAiIHRyYW5zZm9ybT0ibWF0cml4KDAuMjkwNjk4LDAsMCwwLjI5MDY5OCwwLDApIi8+CjwvZz4KPC9zdmc+Cg==',
    status: 'Stopped',
    url: 'https://www.google.com'
  },
  {
    id: 4,
    name: 'Scientific Web App',
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSI1MHB4IiBoZWlnaHQ9IjUwcHgiPgo8ZyBpZD0ic3VyZmFjZTg1NTU1Ij4KPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDk1LjI5NDExOCUsNDUuNDkwMTk2JSwyNy44NDMxMzklKTtmaWxsLW9wYWNpdHk6MTtzdHJva2Utd2lkdGg6My40NDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlOnJnYig5NS4yOTQxMTglLDQ1LjQ5MDE5NiUsMjcuODQzMTM5JSk7c3Ryb2tlLW9wYWNpdHk6MTtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGQ9Ik0gMTUwLjUgNzcuNCBMIDk0LjYgNzcuNCBMIDk0LjYgMjEuNSBMIDc3LjQgMjEuNSBMIDc3LjQgNzcuNCBMIDIxLjUgNzcuNCBMIDIxLjUgOTQuNiBMIDc3LjQgOTQuNiBMIDc3LjQgMTUwLjUgTCA5NC42IDE1MC41IEwgOTQuNiA5NC42IEwgMTUwLjUgOTQuNiBaIE0gMTUwLjUgNzcuNCAiIHRyYW5zZm9ybT0ibWF0cml4KDAuMjkwNjk4LDAsMCwwLjI5MDY5OCwwLDApIi8+CjwvZz4KPC9zdmc+Cg==',
    status: 'Starting',
    url: 'https://www.google.com'
  },
  {
    id: 5,
    name: 'Scientific Web App',
    icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSI1MHB4IiBoZWlnaHQ9IjUwcHgiPgo8ZyBpZD0ic3VyZmFjZTg1NTU1Ij4KPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDk1LjI5NDExOCUsNDUuNDkwMTk2JSwyNy44NDMxMzklKTtmaWxsLW9wYWNpdHk6MTtzdHJva2Utd2lkdGg6My40NDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlOnJnYig5NS4yOTQxMTglLDQ1LjQ5MDE5NiUsMjcuODQzMTM5JSk7c3Ryb2tlLW9wYWNpdHk6MTtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiIGQ9Ik0gMTUwLjUgNzcuNCBMIDk0LjYgNzcuNCBMIDk0LjYgMjEuNSBMIDc3LjQgMjEuNSBMIDc3LjQgNzcuNCBMIDIxLjUgNzcuNCBMIDIxLjUgOTQuNiBMIDc3LjQgOTQuNiBMIDc3LjQgMTUwLjUgTCA5NC42IDE1MC41IEwgOTQuNiA5NC42IEwgMTUwLjUgOTQuNiBaIE0gMTUwLjUgNzcuNCAiIHRyYW5zZm9ybT0ibWF0cml4KDAuMjkwNjk4LDAsMCwwLjI5MDY5OCwwLDApIi8+CjwvZz4KPC9zdmc+Cg==',
    status: 'Stopping',
    url: 'https://www.google.com'
  }
]

export default mockedApps
