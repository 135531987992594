import { Modal as BootstrapModal } from 'bootstrap'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Breakpoint, Container } from 'types'

import styles from './styles.module.scss'

interface Props extends Container {
  centered?: boolean
  fullscreen?: boolean
  fullscreenBelow?: Breakpoint
  onClose: () => void
  title?: string | React.ReactNode
  showModal?: boolean
  size?: Breakpoint
  withHeaderTabs?: boolean
}

const Modal = ({ centered = true, children, className, fullscreen = false, fullscreenBelow, onClose, showModal = false, title = '', size, withHeaderTabs }: Props): React.ReactPortal | null => {
  const modalPortal = document.getElementById('modal-portal')
  if (modalPortal == null) {
    return null
  }

  const modalEl = useRef(null)
  const [modalInstance, setModalInstance] = useState<BootstrapModal>()

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    if (typeof modalInstance === 'object' && Object.keys(modalInstance).length > 0) {
      if (showModal) {
        modalInstance.show()
      } else {
        modalInstance.hide()
      }
    }
  }, [modalInstance, showModal])

  useEffect(() => {
    if (modalEl.current) {
      setModalInstance(new BootstrapModal(modalEl.current))

      ;(modalEl.current as unknown as HTMLElement).addEventListener('hide.bs.modal', handleClose)
    }
  }, [modalEl])

  let dialogClasses = `modal-dialog ${styles.dialog} ${className}`
  if (centered) {
    dialogClasses += ' modal-dialog-centered'
  }
  if (size) {
    dialogClasses += ` modal-${size}`
  }
  if (fullscreen) {
    dialogClasses += ` modal-fullscreen${fullscreenBelow ? `-${fullscreenBelow}-down` : ''}`
  }
  if (withHeaderTabs) {
    dialogClasses += ` ${styles.withHeaderTabs}`
  }

  return ReactDOM.createPortal(
    <div className='modal fade' id='modal' tabIndex={-1} ref={modalEl}>
      <div className={dialogClasses}>
        <div className={`modal-content ${styles.content}`}>
          <div className={`modal-header ${styles.header}`}>
            <h5 className={`modal-title ${styles.title}`}>{title}</h5>

            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={handleClose} />
          </div>
          <div className={`modal-body ${styles.body}`}>
            {children}
          </div>
        </div>
      </div>
    </div>,
    modalPortal
  )
}

export default Modal
