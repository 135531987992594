import { useCallback } from 'react'
import { Container } from 'types'

interface Props extends Container {
  borderless?: boolean
  hover?: boolean
  striped?: boolean
}

const Table = ({ children, className = '', borderless = false, hover = false, striped = false }: Props): JSX.Element => {
  const buildStyleClasses = useCallback(() => {
    let classes = ''
    if (borderless) {
      classes = 'table-borderless'
    }
    if (hover) {
      classes += ' table-hover'
    }
    if (striped) {
      classes += ' table-striped'
    }

    return classes
  }, [borderless, hover, striped])

  return (
    <div className='table-responsive'>
      <table className={`table ${className} ${buildStyleClasses()}`}>
        {children}
      </table>
    </div>
  )
}

export default Table
