import { Container } from 'types'
import styles from './styles.module.scss'

const InternalSidebar = ({ children, className = '' }: Container): JSX.Element => {
  return (
    <ul className={`${styles.applicationInternalSidebar} ${className}`} role='menubar'>
      {children}
    </ul>
  )
}

export default InternalSidebar
