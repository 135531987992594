import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MenuItem from '../MenuItem'
import userIcon from 'assets/images/icons/user.svg'
import buildingIcon from 'assets/images/icons/building.svg'
import logoutIcon from 'assets/images/icons/logout.svg'
import styles from './styles.module.scss'

const ProfileFloatingMenu: React.FC = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.body.addEventListener('click', closeMenu)

    return () => {
      document.body.removeEventListener('click', closeMenu)
    }
  }, [])

  const closeMenu = useCallback((event: MouseEvent): void => {
    const target = event.target as HTMLElement
    if (target.closest(`.${styles.userProfile}`) === null) {
      setOpen(false)
    }
  }, [setOpen])

  const handleToggle = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault()
    setOpen(!open)
  }, [open, setOpen])

  return (
    <MenuItem className={`${styles.userProfile} me-5`}>
      <h3 className={styles.triggerProfileAvatar} onClick={handleToggle}>AK</h3>

      <div className={`${styles.userProfileMenu} ${open ? styles.open : ''}`}>
        <div className={`${styles.userProfileMenuItem} d-flex justify-content-center align-items-center`}>
          <h4 className={styles.internalProfileAvatar}>AK</h4>

          <div className='d-flex flex-column'>
            <span className='fw-semibold'>Amir Khalighi</span>
            <span className='fs-14'>akhalighi@enthought.com</span>
          </div>
        </div>

        <div className={`${styles.userProfileMenuItem} d-flex flex-column`}>
          <Link to='/account-user-info' className={styles.userProfileMenuItemLink}>
            <img src={userIcon} alt='' />
            <span>My Profile</span>
          </Link>

          <Link to='#' className={styles.userProfileMenuItemLink}>
            <img src={buildingIcon} alt='' />
            <span>Switch Organizations</span>
          </Link>
        </div>

        <div className={`${styles.userProfileMenuItem} d-flex flex-column`}>
          <Link to='/' className={styles.userProfileMenuItemLink}>
            <img src={logoutIcon} alt='' />
            <span>Sign Out</span>
          </Link>
        </div>

        <div className={`${styles.userProfileMenuItem} d-flex justify-content-center align-items-center text-dark`}>
          <Link to='#' className='fs-12'>Privacy Policy</Link>
          <span className='fs-12 mx-2'>|</span>
          <Link to='#' className='fs-12'>Terms of Service</Link>
        </div>
      </div>
    </MenuItem>
  )
}

export default ProfileFloatingMenu
