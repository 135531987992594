import { Icon } from 'types'

const EditIcon = ({ width = 16, height = 16, fill = '#FFFFFF', ...props }: Icon): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill={fill} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M13.1817 1.5C13.0085 1.5 12.8371 1.5341 12.6771 1.60035C12.5172 1.66661 12.3719 1.76371 12.2494 1.88613L11.0763 3.05922L12.9408 4.92366L14.1139 3.75057C14.2363 3.62815 14.3334 3.48281 14.3996 3.32286C14.4659 3.16291 14.5 2.99148 14.5 2.81835C14.5 2.64522 14.4659 2.47379 14.3996 2.31384C14.3334 2.15389 14.2363 2.00856 14.1139 1.88613C13.9914 1.76371 13.8461 1.66661 13.6862 1.60035C13.5262 1.5341 13.3548 1.5 13.1817 1.5ZM11.8801 5.98432L10.0157 4.11988L2.51809 11.6175L1.81893 14.1811L4.38252 13.4819L11.8801 5.98432ZM12.1031 0.214534C12.4451 0.0728986 12.8115 0 13.1817 0C13.5518 0 13.9182 0.0728987 14.2602 0.214534C14.6021 0.356169 14.9128 0.563768 15.1745 0.825476C15.4362 1.08718 15.6438 1.39788 15.7855 1.73981C15.9271 2.08175 16 2.44824 16 2.81835C16 3.18846 15.9271 3.55495 15.7855 3.89689C15.6438 4.23882 15.4362 4.54952 15.1745 4.81122L5.30235 14.6834C5.21005 14.7757 5.09527 14.8423 4.96935 14.8766L0.947354 15.9736C0.687696 16.0444 0.409999 15.9706 0.219687 15.7803C0.0293747 15.59 -0.0443716 15.3123 0.0264445 15.0526L1.12335 11.0306C1.1577 10.9047 1.2243 10.7899 1.3166 10.6977L11.1888 0.825476C11.4505 0.563768 11.7612 0.356169 12.1031 0.214534Z' />
    </svg>
  )
}

export default EditIcon
