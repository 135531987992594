import ListGroup from 'components/ListGroup'
import ListGroupItemAnchor from 'components/ListGroup/Item/Anchor'
import { ApplicationProfile } from 'types'

import styles from './styles.module.scss'

interface Props {
  profiles: ApplicationProfile[]
}

const ProfilePicker = ({ profiles }: Props): JSX.Element => {
  return (
    <ListGroup actionList className={`${styles.picker} mb-4`}>
      {profiles.map((profile) => (
        <ListGroupItemAnchor key={profile.name} href={profile.url} target='_blank' className='text-center mb-2'>
          <span>Launch as <span className='fw-semibold'>“{profile.name}”</span> ({profile.cpu} CPU, {profile.gpu} GPU, {profile.memory} GB RAM)</span>
        </ListGroupItemAnchor>
      ))}
    </ListGroup>
  )
}

export default ProfilePicker
