import { Link } from 'react-router-dom'
import { Container } from 'types'

import styles from '../styles.module.scss'

interface Props extends Container {
  to?: string
  onClick?: () => void
  variant?: 'link' | 'button'
}

const FloatingMenuItem = ({ className = '', children, onClick, to = '', variant = 'button', ...props }: Props): JSX.Element => {
  if (variant === 'button') {
    return (
      <button type='button' className={`${styles.floatingMenuItem} ${className}`} onClick={onClick} {...props}>
        {children}
      </button>
    )
  }

  return (
    <Link to={to} className={`${styles.floatingMenuItem} ${className}`} {...props}>
      {children}
    </Link>
  )
}

export default FloatingMenuItem
