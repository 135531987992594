import { useMatches } from 'react-router-dom'
import { Breadcrumb } from 'types'

import BreadcrumbItem from './BreadcrumbItem'

interface Props {
  breadcrumbs: Breadcrumb[]
}

const BreadcrumbsBar = ({ breadcrumbs }: Props): JSX.Element => {
  const matches = useMatches()
  // console.log('matches ', matches)
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        {breadcrumbs.map((b, i) => <BreadcrumbItem key={i} {...b} />)}
      </ol>
    </nav>
  )
}

export default BreadcrumbsBar
